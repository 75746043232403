body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
    height: 100%;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.globeContainer canvas {
    width: 100% !important;
    height: 100% !important;
}

.globeContainer {
    user-select: none;
}

.react-code-input > input {
    font-family: Roboto, serif !important;
    font-weight: bold !important;
}